@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url('@common/editor/quill.scss');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  path {
    @apply tw-outline-none;
  }

  body,
  *,
  *::placeholder {
    font-family: 'Noto Sans JP', sans-serif;
    border-style: solid;
    border-width: 0;
  }

  * {
    @apply tw-m-0 tw-box-border tw-p-0;
    // scroll-behavior: smooth;
  }

  html {
    @apply tw-scrollbar-thin tw-scrollbar-track-white tw-scrollbar-thumb-neutral-2;
  }

  *::-webkit-scrollbar {
    @apply tw-h-6 tw-w-6;
  }

  *::-webkit-scrollbar-track {
    @apply tw-bg-white;
  }

  *::-webkit-scrollbar-thumb {
    @apply tw-rounded-full tw-bg-neutral-2;
  }

  *::placeholder {
    @apply tw-text-neutral-2;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  span,
  label,
  small,
  div,
  a {
    @apply tw-m-0 tw-font-regular tw-text-neutral-7;
  }

  button,
  svg {
    @apply tw-duration-200;
  }

  span,
  svg {
    @apply tw-text-inherit;
  }

  h1 {
    @apply tw-text-h1 pc:tw-text-h1Tablet;
  }

  h2 {
    @apply tw-text-h2 pc:tw-text-h2Tablet;
  }

  h3 {
    @apply tw-text-h3;
  }

  h4 {
    @apply tw-text-h4;
  }

  p {
    @apply tw-text-paragraph;
  }

  small {
    @apply tw-text-tiny;
  }

  button {
    @apply tw-border-none tw-bg-transparent tw-text-paragraph;
  }

  a {
    @apply visited:tw-text-neutral-8;
  }

  section {
    @apply tw-relative tw-left-1/2 tw-max-w-1920 -tw-translate-x-1/2 tw-px-16 pc:tw-px-40 large:tw-px-60;
  }

  mark {
    @apply tw-bg-gradient-to-r tw-from-gradient-from tw-to-gradient-to tw-bg-clip-text tw-text-paragraph tw-font-bold tw-text-transparent;
  }


}

div.tw-prose ul>li {
  margin-left: 20px;
}

div.tw-prose ol>li {
  &:nth-child(n) {
    margin-left: 20px;
  }

  &:nth-child(n + 11) {
    margin-left: 25px;
  }

  &:nth-child(n + 101) {
    margin-left: 30px;
  }
}

.ant-picker-content {
  thead {
    display: none;
  }
}

.ant-picker-footer {
  display: none !important;
}

.timepicker .ant-picker-footer {
  display: block !important;
}

// disabled outline TreeSelect
:where(.css-dev-only-do-not-override-pwjfae).ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  outline: none;
}

:where(.css-pwjfae).ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  outline: none;
}

#spsvIframeWapper {
  #SPSVviewIframe{
    position: fixed !important;
  }
  position: fixed !important;
  padding: 0 !important;
  transform: initial !important;
  top: unset !important;
  left: unset !important;
}

.DataTableTemplate table:has(.ant-empty) {
  height: 100%;
}

body {
  overflow: auto !important;
}
 .disabled-row{
  background-color: #d9d9d9;
 }

.ant-modal-hgb-custom {
  .ant-modal-header {
    margin-bottom: 0;
  }

  .ant-modal-content {
    max-width: auto;
    margin: 0 auto;
  }

  .ant-modal-content {
    max-height: calc(100vh - 100px) !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
    overflow: hidden;
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}

body:has(.ant-modal-mask) {
  overflow-y: hidden !important;
}

.ant-tooltip {
  max-width: fit-content;
}

.ant-table-header {
  .ant-table-thead {
    .ant-table-cell {
      border-inline-end: 2px solid #FFF !important;
      border-bottom: 2px solid #FFF !important;
    }
  }
}
.ant-table-body {
  .ant-table-cell {
    border-inline-end: none !important;
  }
}

.table-highlight-wrapper {
  .ant-table-thead {
    .ant-table-cell {
      background-color: RGB(230, 230, 230);
    }
  }
}